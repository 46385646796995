import React, { useState, useEffect, useContext, useRef } from "react";
import ScreenContext from "../../../context/screen/ScreenContext";
import LocationContext from "../../../context/location/LocationContext";
import nextClass from "./media/NextClass.png";
import "vlitejs/vlite.css";
import Vlitejs from "vlitejs";
import {
  convertTime,
  addToTime,
  timeDiffToLocal,
} from "../../../utils/timeHandlers";
import "./Class.css";
import handleSchedule from "../../../utils/handleSchedule";
import {
  videoValidation,
  updateInvalidLink,
} from "../../../utils/videoValidation";

const Class = () => {
  const screenContext = useContext(ScreenContext);
  const {
    screen,
    workout,
    getCurrentRoundWorkout,
    loading,
    sendScreenStatus,
  } = screenContext;

  const locationContext = useContext(LocationContext);
  const { getLocationSchedule, schedule, clearSchedule } = locationContext;

  const [sequence, setSequence] = useState([]);
  const [start, setStart] = useState(false);
  const [comboStart, setComboStart] = useState(false);
  const [vlite, setVlite] = useState(null);

  const comboInterval = useRef(null);
  const [nextClassTime, setNextClassTime] = useState("");
  const [timeObj, setTimeObj] = useState({});
  const [scheduleObj, setScheduleObj] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const weekDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const countDownMinutes = 5;
  const gracePeriodMinutes = 30;

  // Prevent right clicking
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  // Prevent pausing video
  document.addEventListener("click", (event) => event.preventDefault())

  // Transition to the next video in queue
  const transitionVideo = () => {
    if (sequence && sequence.length > 1) {
      setStart(true);
      const video = document.getElementById("player");
      const updatedSequence = sequence;
      updatedSequence.shift();
      video.src = updatedSequence[0].videoLink;
      setSequence(updatedSequence);
    } else {
      window.location.reload();
    }
    if (sequence.length < 12 && sequence.length > 3 && start) comboEffect();
  };

  // Build the class and & validate each video link
  const startClass = () => {
    const results = workout.routines
    const validateResults = [];
    for (let i = 0; i < results.length; i++) {
      videoValidation(results[i])
        .then((res) => {
          validateResults.push({
            videoLink: results[i].videoLink,
            order: i,
            validated: res,
          });
        })
        .catch((e) =>
          validateResults.push({
            videoLink: results[i].videoLink,
            order: i,
            validated: e,
          })
        );
    }
    promiseBuffer(validateResults);
  };

  // Buffer to ensure promises resolve and then start the class
  const promiseBuffer = (results) => {
    const validatedSequence = [];
    if (results.length === 14 && !start) {
      results.forEach((result, i) => {
        result.validated
          ? validatedSequence.push(results[i])
          : validatedSequence.push(
              updateInvalidLink(results[i])
            );
      });
      const sortedSequence = validatedSequence.sort(
        (a, b) => a.order - b.order
      );
      setSequence(sortedSequence);
      setStart(true);
    } else {
      setTimeout(() => {
        promiseBuffer(results);
      }, 1000);
    }
  };

  const manualMode = () => {
    if (sequence.length === 0 && screen?.classStart) {
      startClass();
    } else if (workout && sequence.length > 0 && !screen?.classStart) {
      window.location.reload();
    }
  };

  const automaticMode = () => {
    if (nextClassTime) {
      const timeZone = screen?.location?.timeZone || "America/New_York";
      const timeDiff =
        timeDiffToLocal(nextClassTime, timeZone) + countDownMinutes; 
      if (timeDiff >= 0 && timeDiff < gracePeriodMinutes) {
        if (!start) {
          console.log("starting the next class...");
          startClass();
        }
      } else if (workout && sequence.length > 0) {
        window.location.reload();
      }
    }
  };

  const comboEffect = () => {
    setComboStart(true);
    comboInterval.current = setTimeout(() => {
      setComboStart(false);
    }, 44000);
  };

  const checkClassStatus = () => {
    // on interval, we check if the screen is set to automatic mode and if there should be a class playing
    if (screen.automaticMode && !start && nextClassTime) {
      automaticMode();
    }
  }

  useEffect(() => {
    if (!Object.keys(timeObj).length) return;
    // we want to start the class if we're still within the grace period
    const filteredClasses = scheduleObj[timeObj?.today].filter(
      (classObj) =>
        addToTime(classObj.class_time, gracePeriodMinutes) > timeObj?.local_time
    );

    setNextClassTime(filteredClasses[0]?.class_time || null);
    // eslint-disable-next-line
  }, [screen, schedule, scheduleObj, timeObj]);

  useEffect(() => {
    if ((!schedule || !Object.keys(schedule).length) && screen) {
      getLocationSchedule(screen?.location?.urlSlug);
      return;
    }

    if (!Object.keys(timeObj).length && screen) {
      // build the time object
      const datetime_str = new Date().toLocaleString(
        screen?.location?.language || "en-US",
        {
          timeZone: screen?.location?.timeZone || "America/New_York",
        }
      );
      const today = weekDays[(new Date(datetime_str).getDay() + 6) % 7];

      // create new Date object
      const date_local = new Date(datetime_str);

      // get hours and minutes with leading zeroes if needed
      let hour = ("0" + date_local.getHours()).slice(-2);
      let minute = ("0" + date_local.getMinutes()).slice(-2);

      // time in hh:mm format
      const local_time = hour + ":" + minute;

      setTimeObj({
        today: today,
        local_time: local_time,
      });
    }

    setScheduleObj({
      ...handleSchedule(schedule),
    });
    // eslint-disable-next-line
  }, [schedule, screen]);

  useEffect(() => {
    if (!workout) getCurrentRoundWorkout(1);
    // eslint-disable-next-line
  }, [workout, loading, screen]);

  useEffect(() => {
    const interval = setInterval(() => {
      sendScreenStatus();
      checkClassStatus();
    }, 30 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (screen && workout && !screen.automaticMode) {
      manualMode();
    } else if (screen && workout && screen.automaticMode) {
      automaticMode();
    }

    // eslint-disable-next-line
  }, [screen, workout]);

  useEffect(() => {
    if (sequence && sequence.length > 0 && !vlite) {
      const newInstance = new Vlitejs("#player", {
        options: {
          controls: false,
          autoplay: true,
          playPause: false,
          progressBar: false,
          time: false,
          volume: false,
          fullscreen: false,
          bigPlay: false,
          playsinline: true,
          loop: false,
          muted: false,
          autoHide: true,
          autoHideDelay: true,
        },
        onReady: (player) => {
          player.on("ended", () => {
            transitionVideo();
          });
          player.on("pause", () => {
            player.play();
          });
        },
      });
      setVlite(newInstance);
    }
    //eslint-disable-next-line
  }, [start]);

  useEffect(() => {
    return () => {
      clearTimeout(comboInterval.current);
      clearSchedule();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="ClassContainer">
      <video
        src={
          start && sequence && sequence.length > 0 ? sequence[0].videoLink : ""
        }
        id="player"
        className="Class"
        controlsList="nodownload"
      ></video>

      {comboStart && sequence && sequence.length > 0 && (
        <div id="combo-overlay-container">
          <h1 id="combo-overlay-text">COMBO # {sequence[0].order - 2}</h1>
        </div>
      )}
      {!start && (
        <>
          <img id="next-class-img" src={nextClass} alt="class-start"></img>
          <div id="next-class-container">
            {screen.automaticMode ? (
              <>
                {nextClassTime ? (
                  <>
                    <h1 id="next-class-header">Next class starts at</h1>
                    <h2 id="next-class-time">
                      {nextClassTime && convertTime(nextClassTime, 12)}
                    </h2>
                  </>
                ) : (
                  <h1 id="next-class-header">
                    No more classes
                    <br />
                    scheduled for today.
                  </h1>
                )}
              </>
            ) : (
              <>
                <h1 id="welcome-to-ilkb-header">
                  Welcome to iLoveKickboxing,
                  <br />
                  {screen?.location?.name}!
                </h1>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Class;
