import React from "react";

const Button = ({ id="", cssClasses, action, content, disabled }) => {
  const onButtonClick = (event) => {
    event.preventDefault();

    event.currentTarget.blur();

    action(event);
  };

  return (
    <button id={id} className={cssClasses} onClick={onButtonClick} disabled={disabled} >
      {content}
    </button>
  );
};

export default Button;
