import React, { useState, useEffect, useContext } from "react";
import LocationContext from "../../../context/location/LocationContext";
import ScreenContext from "../../../context/screen/ScreenContext";
import Spinner from "../../layout/Spinner";
import Button from "../../common/Button";
import { convertTime } from "../../../utils/timeHandlers";
import handleSchedule from "../../../utils/handleSchedule";
import AlertContext from "../../../context/alert/AlertContext";

const DashboardSchedule = ({ user }) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const locationContext = useContext(LocationContext);
  const { getLocationSchedule, loading, schedule, clearSchedule } =
    locationContext;
  const screenContext = useContext(ScreenContext);
  const { updateScreen, clearScreen, clearScreens, getScreens, screens } =
    screenContext;
  const [classScreen, setClassScreen] = useState(null);
  const [autoMode, setAutoMode] = useState(classScreen?.automaticMode || true);
  const [classStart, setClassStart] = useState(classScreen?.classStart || true);

  const locations = user?.locations;

  // create empty schedule object to fill with fetched data
  const [scheduleObj, setScheduleObj] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const datetime_str = new Date().toLocaleString(
    locations[0]?.language || "en-US",
    {
      timeZone: locations[0]?.timeZone || "America/New_York",
    }
  );

  const today = (new Date(datetime_str).getDay() + 6) % 7;

  /**
   * TODO: make this dynamic when we decide how to handle multiple locations per user
   */
  const getClassScreens = () => {
    for (const s of screens) {
      if (s.id === locations[0]?.screens[0]) {
        setClassScreen(s);
        break;
      }
    }
  };
  useEffect(() => {
    if (!Object.keys(schedule).length && locations)
      getLocationSchedule(locations[0]?.urlSlug);
    return () => {
      clearScreen();
      clearScreens();
      clearSchedule();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (classScreen) {
      setAutoMode(classScreen.automaticMode);
      setClassStart(classScreen.classStart);
    }
    // eslint-disable-next-line
  }, [locations, schedule]);

  useEffect(() => {
    if (schedule && Object.keys(schedule).length) {
      setScheduleObj({
        ...handleSchedule(schedule),
      });
    }
  }, [schedule, loading]);

  useEffect(() => {
    if (!classScreen) return;
    updateScreen({
      ...classScreen,
      classStart: classStart,
      automaticMode: autoMode,
    });
    // eslint-disable-next-line
  }, [classStart, autoMode]);

  const handleToggleAuto = () => {
    setAutoMode(!autoMode);
  };

  const handleClassStartChange = (newStatus) => {
    setClassStart(newStatus);
    setAlert({
      message: newStatus === true ? "Starting Class" : "Stopping Class",
      type: "info",
    });
  };

  useEffect(() => {
    if (locations.length > 0) {
      if (!screens || (screens && screens.length < 1)) getScreens();
      if (!classScreen && screens) getClassScreens();
    }
    // eslint-disable-next-line
  }, [screens, classScreen]);

  return (
    <>
      {!loading && classScreen ? (
        <div className="pb-4">
          <div className="control-group">
            <h3 className="dashboard-controls-header">Manual Class Controls</h3>
            <Button
              id="class-stop-btn"
              action={() => handleClassStartChange(false)}
              disabled={autoMode || !classStart}
              cssClasses={`btn btn-lg ${
                autoMode
                  ? "btn-outline-secondary"
                  : !classStart
                  ? "btn-outline-danger"
                  : "btn-danger"
              } `}
              content={
                <>
                  Stop <i className="fas fa-hand-paper"></i>
                </>
              }
            />
            <span> </span>
            <Button
              id="class-start-btn"
              action={() => handleClassStartChange(true)}
              disabled={autoMode || classStart}
              cssClasses={`btn btn-lg ${
                autoMode
                  ? "btn-outline-secondary"
                  : classStart
                  ? "btn-outline-success"
                  : "btn-success"
              } `}
              content={
                <>
                  Start <i className="fas fa-play"></i>
                </>
              }
            />
            <i className={"automode-subtext" + (!autoMode ? " hide" : "")}>
              Turn off Automatic Mode to enable controls above.
            </i>
          </div>
          <span> </span>
          <div className="control-group">
            <h3 className="dashboard-controls-header">Automatic Mode</h3>
            <div className="switch-container">
              <label className="switch">
                <input
                  id="auto-mode-btn"
                  type="checkbox"
                  checked={autoMode}
                  onChange={handleToggleAuto}
                />
                <span className="slider round"></span>
              </label>
              <span className="control-status">{autoMode ? "ON" : "OFF"}</span>
            </div>
          </div>
        </div>
      ) : !classScreen ? (
        <>
          <span className="alert alert-info" fontSize="1rem">
            User does not have any locations assigned to them.
          </span>
          <br />
          <br />
        </>
      ) : (
        <Spinner />
      )}
      {locations[0] && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
            {schedule && (
              <h3>
                Class Schedule for{" "}
                <span className="text-capitalize">{locations[0]?.urlSlug}</span>
              </h3>
            )}
          </div>

          {!loading ? (
            Object.keys(scheduleObj).length > 0 ? (
              <table className="table table-sm dashboard-schedule">
                <thead>
                  <tr>
                    {Object.keys(scheduleObj).map((day, index) => (
                      <th
                        key={index}
                        className={
                          "py-2 " + (index === today ? "col-today" : "")
                        }
                      >
                        {day.toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(scheduleObj).map((day, index) =>
                      scheduleObj[day].length > 0 ? (
                        <td key={index}>
                          {Array.isArray(scheduleObj[day]) &&
                            scheduleObj[day].map((hour) => (
                              <div key={hour.class_time} className="class-tile">
                                <span className="text-capitalize">
                                  {hour.class_name}
                                </span>
                                <br />
                                <strong className="text-lowercase">
                                  {convertTime(hour.class_time, 12).replace(
                                    /^0|\s/g,
                                    ""
                                  )}
                                </strong>
                              </div>
                            ))}
                        </td>
                      ) : (
                        <td key={index} className="no-classes"></td>
                      )
                    )}
                  </tr>
                </tbody>
              </table>
            ) : (
              <span className="alert alert-warning" fontSize="1rem">
                {locations[0]
                  ? "No class schedule data found."
                  : "No location data found for this user."}
              </span>
            )
          ) : (
            <span>
              Loading class schedule...
              <Spinner />
            </span>
          )}
        </>
      )}
    </>
  );
};

export default DashboardSchedule;
