import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LocationContext from "../../../context/location/LocationContext";
import AlertContext from "../../../context/alert/AlertContext";
import { languagesUSCA } from "../../../utils/languageCodes";
import { timeZonesUS } from "../../../utils/timeZones";
import LockButton from "../../common/LockButton";
import BackButton from "../../common/BackButton";
import Spinner from "../../layout/Spinner";
import LocationScreenList from "./LocationScreenList";
import ScreenContext from "../../../context/screen/ScreenContext";

const LocationItem = ({ url }) => {
  const params = useParams();
  const locationId = params ? params.locationId : undefined;
  const alertContext = useContext(AlertContext);
  const locationContext = useContext(LocationContext);
  const screenContext = useContext(ScreenContext);
  const {
    error,
    loading,
    current,
    getLocation,
    updateLocation,
    addLocationScreen,
    clearCurrent,
  } = locationContext;

  const { setAlert } = alertContext;
  const { updateScreen } = screenContext;

  const [location, setLocation] = useState({
    name: "",
    _id: undefined,
    urlSlug: "",
    language: "",
    timeZone: "",
    locationIdentifier: "",
    isActive: "",
    isEnabled: "",
    screens: [],
    schedule: [],
    vtsEnabled: "",
  });

  const [locked, setLocked] = useState(true);

  useEffect(() => {
    if (!current) {
      getLocation(locationId);
    }

    handleLocation(current);

    // Update the auth code for security every hour
    const interval = setInterval(() => {
      getLocation(locationId);
    }, 45 * 1000);

    return () => clearInterval(interval);


    // eslint-disable-next-line
  }, [error, current, loading]);

  // On unmount clear current
  useEffect(() => {
    return () => clearCurrent();
    // eslint-disable-next-line
  }, []);

  const handleLocation = (location) => {
    setLocation({ ...current, ...location });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    updateLocation(location);
    setAlert({
      message: `Updated Location: ${location.name}`,
      type: "success",
    });

    setLocked(true);
    event.currentTarget.blur();
  };

  const onChange = (event) => {
    let value = null;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    handleLocation({
      ...location,
      [event.target.name]: value,
    });
  };

  const handleHardRefresh = () => {
    for (let i = 0; i < location.screens.length; i++) {
      updateScreen({
        hardRefreshFlag: true,
        ...location.screens[i],
      });
    }
    setAlert({
      message: `${location.name} screens are currently refreshing, please wait 30-60 seconds to see live changes.`,
      type: "success",
    });
  };

  return (
    <>
      {current && !loading && (
        <>
          <form onSubmit={onSubmit}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h2>
                <LockButton locked={locked} setLocked={setLocked} />{" "}
                {location.name}
              </h2>
              <div className="btn-group ">
                <BackButton url={url} />
                {!locked && (
                  <button type="submit" className="btn btn-primary">
                    <i className="fas fa-save"></i> Update
                  </button>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  title="Name of Location"
                  value={location.name || ""}
                  placeholder="85 characters (max)"
                  maxLength={85}
                  onChange={onChange}
                  disabled={locked}
                  required={true}
                />
              </div>
              <div className="form-group col-3">
                <label htmlFor="">Studio Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="locationIdentifier"
                  title="Prefix ILKB, can include country code followed by 4+ digits"
                  value={location.locationIdentifier || ""}
                  placeholder="ex ILKB0007"
                  onChange={onChange}
                  disabled={true}
                  required={true}
                  />
              </div>
              <div className="form-group col-3">
                <label htmlFor="">URL Slug</label>
                <input
                  className="form-control"
                  type="text"
                  name="urlSlug"
                  title="URL slug for this location"
                  value={location.urlSlug || ""}
                  placeholder="ex raleigh"
                  onChange={onChange}
                  disabled={locked}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-3">
                <label htmlFor="">Time Zone</label>
                <select
                  type="text"
                  name="timeZone"
                  title="Select a Time zone"
                  className="form-control"
                  value={location.timeZone || ""}
                  onChange={onChange}
                  disabled={locked}
                  required={true}
                >
                  <option></option>
                  {timeZonesUS.map((tz, i) => (
                    <option key={i} value={tz}>
                      {tz}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-3">
                <label htmlFor="language">Language</label>
                <select
                  className="form-control"
                  name="language"
                  title="Select a Language"
                  onChange={onChange}
                  disabled={locked}
                  required={true}
                  value={location.language || ""}
                >
                  <option></option>
                  {languagesUSCA.map((lang, i) => (
                    <option key={i} value={lang}>
                      {lang}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-check form-check-inline ml-3">
                <input
                  type="checkbox"
                  name="isActive"
                  title="Toggle Location Active"
                  className="form-check-input"
                  checked={location.isActive || false}
                  onChange={onChange}
                  disabled={locked}
                />
                <label htmlFor="isActive" className="form-check-label">
                  Location Active
                </label>
              </div>
              <div className="form-check form-check-inline ml-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isEnabled"
                  title="Toggle Location Screens"
                  checked={location.isEnabled || false}
                  onChange={onChange}
                  disabled={locked}
                />
                <label htmlFor="isEnabled" className="form-check-label">
                  Enable Location Screens
                </label>
              </div>
              <div className="form-check form-check-inline ml-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="lrVideo"
                  title="Toggle Non Vimeo Videos"
                  checked={location.lrVideo || false}
                  onChange={onChange}
                  disabled={locked}
                />
                <label htmlFor="lrVideo" className="form-check-label">
                  LR Video
                </label>
              </div>
              <div className="form-check form-check-inline ml-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="androidOs"
                  title="Android OS Screens"
                  checked={location.androidOs || false}
                  onChange={onChange}
                  disabled={locked}
                />
                <label htmlFor="androidOs" className="form-check-label">
                  Android OS
                </label>
              </div>
            </div>
          </form>
          <LocationScreenList
            locked={locked}
            location={location}
            addLocationScreen={addLocationScreen}
          />
          <button
            className="btn btn-sm btn-outline-info"
            style={{
              color: "lightblue",
              fontWeight: "bolder",
              float: "right",
              marginRight: "30px",
              marginTop: "10px",
            }}
            onClick={handleHardRefresh}
          >
            Hard Refresh <i className="fas fa-exclamation-triangle"></i>
          </button>
        </>
      )}
      {(!current || loading) && <Spinner />}
    </>
  );
};

export default LocationItem;
